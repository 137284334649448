.wrapper{
  display: grid;
  grid-gap: 50px;
  background-color: #fff;
  color: #444;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 200px);

  .col{
    background-color: #444;
    color: #fff;
    border-radius: 5px;
    padding: 20px;
    font-size: 150%;
  }
}

main {
  section {
    
    .container {
      header {
        h2 {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
            width: 60px;
            height: 4px;
            
          }
          
        }

        .img-container {
          margin-top: 1.6rem;
          text-align: center;

          img {
            min-width: 240px;
            max-width: 500px;
            height: auto;
          }
        }
      }

      .product-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        ul  {
          li {
            list-style: circle;
          }
        }
        h3 {
          padding-bottom: 1.6rem;
        }
        .list-2 h3 {
          padding-top: 1.6rem;
        }
      }
    }
  }
}

#products .img-container::before {
  content: '';
  height: 100%;
  width: 500px;
  max-width: 500px;
  background: hsl(var(--color-hue) 65% 88%);
  position: absolute;
  top: 8.2%;
  z-index: 0;
  //overflow: hidden;
  @media screen and (max-width: 1120px) {
    top: 8.2%;
    left: 11%;
  }
}