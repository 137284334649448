#compliance {
  .img-container {
    margin-top: 1.6rem;
    text-align: center;
    img {
      min-width: 240px;
      max-width: 500px;
      height: auto;
    }
  }
}

#compliance .img-container::before {
  content: '';
  height: 100%;
  width: 100%;
  max-width: 500px;
  background: hsl(var(--color-hue) 65% 88%);
  position: absolute;
  top: 8.2%;
  z-index: 0;
  //overflow: hidden;
  @media screen and (max-width: 1120px) {
    top: 8.2%;
    left: 11%;
  }
}
//frmshc.Separacao.Main.Flds.fmeProdutosSeparacao.Campos.InputsHidden.cnProduto