.button-modal {
  margin-top: 1.2rem;
}
.ReactModal__Overlay  {
  z-index: 100;
}
.ReactModal__Content {
  margin-right: 0 !important;
  
  @media screen and (max-width:590px) {
    width: 90%;
  }
  @media screen and (max-width:330px) {
    height: 400px;
  }
}

.modal-list {
  padding: 2.4rem;

  h3 {
    padding-bottom: 1.6rem;
  }

  ul {
    li {
      list-style: circle !important;
      padding: 0.876rem;
    }
  }

  .btn-fechar{
    display: flex;
    justify-content: center;
    margin: 1.6rem 0;
  }
}