@import url('./variables.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
/*========= MEDIA QUERIES =========*/

/*================ EXTRA SMALL DEVICES ====================*/
@media (max-width:320px) {
  body {
    font: 400 1.44rem 'Poppins',sans-serif;
  }
  
  nav, .container {
    // max-width: 1366px;
    margin-left: 11.2rem;
    margin-right: 11.2rem;
    a {
      .logo {
        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          box-sizing: border-box;
          padding: 0;
          border: none;
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
    .suport_btn{
      display: none;
    }
  }
  
  #home, #about, #products, #testimonials, #contact{
      .section {
        padding: 9.6rem 0;
      }
      .container {      
          h2,h3 {
            font: 700 1.39rem 'Poppins',sans-serif;               
        }
      }
    
  }
  

}

@media (min-width:320px) {
  body {
    font: 400 1rem 'Poppins',sans-serif;
  }
  
  nav, .container {
    // max-width: 1366px;
    margin-left: 11.2rem;
    margin-right: 11.2rem;
    
    .suport_btn{
      display: none;
    }
  }
}

/*================ TABLET ====================*/
@media (min-width: 650px) {
  body {
    font: 400 1rem 'Poppins',sans-serif;
  }
  
  nav, .container {
    // max-width: 1366px;
    margin-left: 11.2rem; 
    margin-right: 11.2rem;
    
    .suport_btn{
      display: none;
    }
  }  

  section .title {
    
    font-size: 2.92rem;
  }
  /* layout */
  main {
    margin-top: var(--header-height);
    
    .section, +#contact {     
      .container {

        header .title, header p {
          text-align: center;
        }

        .text {
          width: 100%;
          text-align: left;         
        }
      
        .cards{      
          .card {
            padding-left: 4.8rem;
            padding-right: 4.8rem;
          }
        }
      }
    }

    //===== HOME =====\\
    #home {
      .container {

        .text {

          p {
            margin-left: 2.4rem;
            margin-right: 2.4rem;
          }
        }

        .image {
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;

          img {
            max-width: 570px;
            right: -1.2rem;   
          }
          
          &::before {
            max-width: 100%;
            height: 100%;
            top: -12.8%;
            left: 38%;
          }
        }

        .text {
          
          margin-bottom: 2.4rem;
          margin: 0 auto;
          >h2 {
            margin-bottom: 0rem;
            // font-size: 2.11.2rem;

          }

          >p {
            margin-top: 0rem;
          }

          .button {
            padding: 0 3.5rem;
          }
        }
      }
    }
    
    //===== ABOUT =====\\
    #about {
      .container {
        margin: 0 5rem;
        
        .image {
          order: 0;
          max-width: 570px;
          right: -4rem;
          position: relative;

          &::before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: -12.3%;
            left: -14%;
            z-index: 0;
          }
        }

        .text {          
          font-size: 1.6rem;
          text-align: left;
          margin: 0 auto;
          
          h2 {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 10%;
              transform: translateX(-50%);
              bottom: -4px;
              width: 120px;
              height: 4px;
            }
          }
        }
      }
    }

    //===== PRODUCTS =====\\
    #products {
      .container {
        padding: 0 9.6rem;

          header {
            text-align: center;
          }        
        
        h2 {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
            width: 60px;
            height: 4px;          
          }        
        }
      }
    }

    //===== TESTIMONIALS =====\\
    #testimonials {
      .container {
        header {
          text-align: center;
        }
      }
    }
    //===== CONTACTS =====\\
    #contact {
      .container {
        padding: 0;
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 50%);

        .text {
          max-width: 25rem;

          h2 {
          text-align:left; 
          }

          p {
            margin-bottom: 3.2rem;
          }
        }

        #form {
          display: grid;
          justify-content: center;
          flex-wrap: wrap;

          >h2 {
            text-align: center;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
              width: 60px;
              height: 4px;              
            }
          }         

          .send {
            .button {
              padding: 0 3.5rem;
              margin-left: 0;
            }
          }
        }       
      }
      .grid {
        gap: 6.4rem;
      }
    }

    .swiper-slide {
      width: 50% !important;
    } 
  }


  #footer {
    .footer-col {
      padding: 0 0 0 1rem;
    }
  }

}


/*================ NOTEBOOK ====================*/
@media (min-width: 912px) {
  body {
    font: 400 1rem 'Poppins',sans-serif;
  }
  
  nav, .container {
    // max-width: 1366px;
    margin-left: 11.2rem;
    margin-right: 11.2rem;
    
    .suport_btn{
      display: none;
    }
  }  

  section .title {
    font-size: 2.92rem;
  }

  /* layout */
  main {
    //aqui
    .section, +#contact {     
      .container {

        header .title, header p {
          // max-width: 32rem;
          text-align: center;
        }

        .text {
          width: 100%;
          text-align: left;            
        }
 
        .cards{

          .card {
            padding-left: 4.8rem;
            padding-right: 4.8rem;
          }
        }
      }
    }

    //===== HOME =====\\
    #home {
      .container {
        padding: 4rem 0;
        grid-auto-flow: column;
        justify-content: space-between;
        margin: 0 auto;

        .text {
          width: 60%;
          p {
            margin-left: 2.4rem;
            margin-right: 2.4rem;
          }
        }

        .image {
          order: 1;
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;

          img {
            max-width: 570px;
            right: -1.2rem;       
          }

          &::before {
            
            width: 100%;
            height: 100%;
            left: 7rem;
            top: -6rem;
        
          }
        }

        .text {
          min-width: 100%;
          margin-bottom: 2.4rem;
          margin: 0 auto;
          >h2 {
            margin-bottom: 0rem;
          }

          >p {
            margin-top: 0rem;
          }

          .button {
            padding: 0 3.5rem;
          }
        }
      }
    }
    
    //===== ABOUT =====\\
    #about {
      .container {   
        padding: 1.2rem 0;
        grid-auto-flow: column;
        justify-content: space-between;
        // gap: 0;;
        
        .image_about {
          order: 0;
          width: 500px;
          position: relative;
          left: 0;
          top: 5rem;
          

          &::before {
            content: "";
            height: 65%;
            width: 100%;
            position: absolute;
            top: -6.7%;
            left: -14%;
            z-index: 0;
          }
        }

        .text { 
          
          font-size: 1.6rem;
          text-align: left;
          width: 100%;
          
          h2 {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              transform: translateX(-50%);
              bottom: -4px;
              width: 120px;
              height: 4px;
              left: 6rem;
              
            }
          }
        }
      }
    }

    //===== PRODUCTS =====\\
    #products {
     

      .container {
        padding: 0 9.6rem;
        
        h2 {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
            width: 60px;
            height: 4px;
            
          }
          
        }
      }
    }
    //===== CONTACTS =====\\
    #contact {

      .container {
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 50%);

        .text {
          max-width: 100%;

          p {
            margin-bottom: 3.2rem;
          }
        }

        #form {
          display: grid;
          justify-content: center;
          flex-wrap: wrap;

          >h2 {
            text-align: center;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
              width: 60px;
              height: 4px;           
            }
          }

          .send {
            .button {
              padding: 0 3.5rem;
              margin-left: 0;
            }
          }
        }

        
      }
      .grid {
        gap: 6.4rem;
      }
    }


    .swiper-slide {
      width: 50% !important;
    }
  }

  #footer {
    .footer-col {
      padding: 0 0 0 1rem;
    }
  }
}


/*================ NOTEBOOK ====================*/
@media (min-width: 1032px) {
  body {
    font: 400 1rem 'Poppins',sans-serif;
  }
  
  #header {
    margin-bottom: 0;

    .container {
      justify-content: left;
      margin-left: 11.2rem;
    }
  }
  
  nav, .container {
    margin-left: 11.2rem;
    margin-right: 11.2rem;
    gap: 10rem;

    .toggle {
      display: none;
      visibility: hidden;
    }

    .suport_btn {
      display: block;   
      position: absolute;
      right: 3.84rem;
    }  
    
    .logo{
      margin: 1.4375rem 0 1.4375rem  0;
    }

    .menu {
      opacity: 1;
      visibility: visible;
      top: 0;

      ul {
        display: flex;
        gap: 3.2rem;

        li{

          a { 
            
            &.title {
              font: 400 1.6rem  'Poppins';
              color: #333;       
            }

            &::after{
              position: relative;
              content: "";
              display: block;
              width: 0;
              height: 2px;
              
              transition: width 0.3s;
              top: -3px;          
            }
            
            &:hover::after{
              width: 100%;
              transition: width .3s;
            }
          }
        }
        
      }
    }
  }  
  /* layout */
  main {
    .section {
      padding:11.2rem 3.2rem;
      height: 100%;
    }

    .section, +#contact {
      .container {
        //padding: 14.4rem 6.4rem;


        header .title, header p {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }

        .image {
          order: 1;
          
          // max-width: 800px;
          &::before {
            
          }
         
        }

        .text {
          order: 0;
          text-align: left;
        }
        
        
        
        .cards{
          grid-template-columns: 1fr 1fr 1fr;

          .card {
            padding-left: 4.8rem;
            padding-right: 4.8rem;
          }
        }

      }
     
    }

    //===== HOME =====\\
    #home {
      padding: 0;

      .container {
        grid-auto-flow: column;
        justify-content: space-between;
        margin: 0 auto;

        .image {

          img {
            // max-width: 480px;
            right: -1.2rem;

          }

          &::before { 
            max-width: 100%;
            height: 90%;
            top: -8.8%;

          }
        }

        .text {
          margin-bottom: 2.4rem;
          
          >h2 {
            margin-bottom: 0rem;
            // font-size: 2.11.2rem;

          }

          >p {
            margin-top: 3.2rem;
          }

          .button {
            padding: 0 3.5rem;
          }
        }
      }
    }
  
    //===== PRODUCTS =====\\
    #products {
      padding: 5rem 0 14rem 0;
      .container {
        padding: 3.2rem 3.2rem;

        .card {
          padding: 2.4rem 2.4rem;
        }
        h2 {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
            width: 60px;
            height: 4px;
            
          }
          
        }
      }
    }

    #testimonials {
        .container {
          padding: 0;
        }
    }

    //===== CONTACTS =====\\
    #contact {
      padding: 11.2rem 11.2rem;

      .container {
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 45%);


        .text {
          max-width: 25rem;

          h2 {
                  
          }
          p {
            margin-bottom: 3.2rem;
          }
        }

        #form {
          display: grid;
          justify-content: center;
          flex-wrap: wrap;


          >h2 {
            text-align: center;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
              width: 60px;
              height: 4px;
              
            }
          }

          

          .send {
            .button {
              padding: 0 3.5rem;
              margin-left: 0;
            }
          }
        }

        
      }
      .grid {
        gap: 6.4rem;
      }
    }


    .swiper-slide {
      width: 50% !important;
    }
  }

  #footer {
    .footer-col {
      padding: 0 0 0 6.4rem;
    }
  }
}

/*================ EXTRA LARGE COMPUTERS ====================*/
@media (min-width: 1280px) {
  body {
    font: 400 1.05rem 'Poppins',sans-serif;
  }

  #header {
    
    nav, .container {
      margin-left: 11.2rem;
      margin-right: 3.5rem;
      justify-content: left;

      .suport_btn {
        position: absolute;
        position: absolute;
        right: 11.2rem;

        .icones {
          font-size: 1.05rem;
          margin-right: 0.625rem;
        }
      }

      .logo{
        margin: 1.4375rem 0 1.4375rem  0;
      }
      .menu {
        opacity: 1;
        visibility: visible;
        top: 0;

        ul {
          display: flex;
          gap: 3.2rem;

          li{

            a { 
              position: unset;
              &.title {
                font: 400 1.6rem "Poppins", sans-serif;
                color: #333;
                
              }
            }
          }
        }
    
      }

      .toggle {
        display: none;
      }
    }
  }


 
  /* layout */
  main {

    margin-top: var(--header-height);

    

    .section, +#contact {
      

      .container {
        
        header .title, header p {
          // max-width: 32rem;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }

        .image {
          order: 1;
          
        }

        .text {
          order: 0;
          max-width: 24rem;
          text-align: left;
        }

        
        .links {
          ul {
            
            li {
              
            }
          }
        }
        .cards{
          grid-template-columns: 1fr 1fr 1fr;

          .card {
            padding-left: 4.8rem;
            padding-right: 4.8rem;
          }
        }


      }
    }

    //===== HOME =====\\
    #home {
      margin: 0 3.5rem;
      padding: 11.2rem 0;

      .container {
        grid-auto-flow: column;
        justify-content: center;
        gap: 5rem;


        .image {
          right: -0.011.2rem;

          img {
            
          } 

          &::before{
            width: 100%;
            height: 100%;
            top: -12.8%;
          }
        }

        .text {
          margin-bottom: 2.4rem;
          max-width: 100%;
          
          >h2 {
            margin-bottom: 0rem;
            font-size: 2.1rem;

          }

          >p {
            margin-top: 3.2rem;
          }

          .button {
            padding: 0 3.5rem;
          }
        }
      }
    }
    
    //===== ABOUT =====\\
     #about {
      

      .container {
        gap: 4rem;
        padding: 11.2rem 0;

         .image {
           order: 0;
           max-width: 100%;
           height: 100%;   

           &::before {
            content: "";
            height: 73%;
            width: 100%;
            position: absolute;
            top: -12.7%;
            left: -14%;
            z-index: 0;
           }
         }

         .text {
           left: 0;
           max-width: 90%;
          
           h2 {
             position: relative;

             &::before {
                content: '';
                position: absolute;
                // left: 50%;
                transform: translateX(-50%);
                bottom: -4px;
                width: 120px;
                height: 4px;
                
             }
           }
         }
       }
     }

    //===== PRODUCTS =====\\
    #products {
      .container {
        padding: 5.2rem 4.8rem;

        header {

          p {
            max-width: 100%; 
          } 
        }
        h2 {
          position: relative;

          

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
            width: 60px;
            height: 4px;
            
          }
          
        }
      }
    }
    //===== CONTACTS =====\\
    #contact {

      .container {
        padding: 3.2rem 11.2rem;
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 45%);


        .text {
          max-width: 25rem;

          h2 {
           
            
          }
          p {
            margin-bottom: 3.2rem;
          }
        }

        #form {
          display: grid;
          justify-content: center;
          flex-wrap: wrap;


          >h2 {
            text-align: center;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
              width: 60px;
              height: 4px;
              
            }
          }

          

          .send {
            .button {
              padding: 0 3.5rem;
              margin-left: 0;
            }
          }
        }

        
      }
      .grid {
        gap: 6.4rem;
      }
    }


    .swiper-slide {
      width: 50% !important;
    }

    

  }

  
  #footer {
    .footer-col {
      padding: 0 0 0 11.2rem;
    }
  }
  
 
  
}


/*================ EXTRA LARGE COMPUTERS ====================*/
@media (min-width: 1400px) {

  #header nav, #header .container {
    margin-left: 11.2rem;
    margin-right: 11.2rem;
    justify-content: left; 
  }
  /* layout */
  main {
    margin-top: var(--header-height);

    section {
      padding: 0;
      margin: 0;
    }
    

    .section, +#contact {
      

      .container {
        
        header .title, header p {
          max-width: 100%;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }

        .image {
          order: 1;
          
        }

        .text {
          order: 0;
          max-width: 24rem;
          text-align: left;
        }

        
        .links {
          ul {
            
            li {
              
            }
          }
        }
        .cards{
          grid-template-columns: 1fr 1fr 1fr;

          .card {
            padding-left: 4.8rem;
            padding-right: 4.8rem;
          }
        }


      }
    }

    //===== HOME =====\\
    #home {
      margin: 0 3.5rem;

      .container {
        grid-auto-flow: column;
        justify-content:center;
        margin: 0 auto;
        gap: 20rem;
        padding: 11.4rem 6.4rem;

        .image {
          right: -1.12rem;

          img {
            
          } 

          &::before{
            width: 100%;
            height: 100%;
            top: -12.8%;
          }
        }

        .text {
          .title {
            font-size: 2.8rem;
          }

          font-size: 1.6rem;
          margin-bottom: 2.4rem;
          max-width: 47rem;
        
          
          >h2 {
            margin-bottom: 0rem;
            font-size: 1.2rem;

          }

          >p {
            margin-top: 3.2rem;
          }

          .button {
            padding: 0 3.5rem;
          }
        }
      }
    }
    
    //===== ABOUT =====\\
    #about {
      
      padding: 11.2rem;

      .container {
        gap: 0;
        grid-auto-flow: column;
        justify-content: center;    

        .image {
          order: 0;

          img {   
            max-width: 100%;     
          }

          &::before {
            content: "";
                height: 73%;
                width: 100%;
                position: absolute;
                top: -12.7%;
                left: -14%;
                z-index: 0;
          }
        }

        .text {
          left: -3rem;
          max-width: 80%;
          
          h2 {
            position: relative;

            &::before {
            
              
            }
          }
        }
      }
    }

    //===== PRODUCTS =====\\
    #products {
      .container {
        padding: 14.4rem 6.4rem;

        h2 {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -5px;
            width: 60px;
            height: 4px;
            
          }
          
        }
      }
    }

    #testimonials {
      .container {
        padding: 14.4rem 6.4rem;
      }
    }
    //===== CONTACTS =====\\
    #contact {

      .container {
        padding: 3.2rem 11.2rem;
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: repeat(auto-fit, 45%);


        .text {
          max-width: 47rem;

          h2 {
           
            
          }
          p {
            margin-bottom: 3.2rem;
          }
        }

        #form {
          display: grid;
          justify-content: center;
          flex-wrap: wrap;


          >h2 {
            text-align: center;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
              width: 60px;
              height: 4px;
              
            }
          }

          

          .send {
            .button {
              padding: 0 3.5rem;
              margin-left: 0;
            }
          }
        }

   
      }
      .grid {
        gap: 6.4rem;
      }
    }

    .swiper-slide {
      width: 50% !important;
    }
  }

  
  #footer {
    .footer-col {
      padding: 0 0 0 11.2rem;
    }
  }
}

/*================ EXTRA EXTRA  LARGE COMPUTERS ====================*/
@media (min-width: 1680px) { 
  main {
    #about {
      padding: 16.9rem;

      .container {
        margin: 0 11.2rem;
        .image {
          
          img {
            width: 100%;

            
          }
          &::before {
            height: 85%;
          }
        }
      }
    }
  }
}